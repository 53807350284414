.order-body {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
}
.order-body::-webkit-scrollbar {
    width: 8px;
}

.order-body::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.order-body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.add-info{
    font-weight: 600;
}