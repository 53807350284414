.account-page .customizer-links,.error-page .customizer-links{ 
  display: none;
}
.customizer-links {
    @include position(fixed,50%,0,null,null);
    @include transform(translateY(-50%));
    background: $orange;
    border-radius: 10px 0px 0px 10px;
    z-index: 3;
    width: 41px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    @include transition(all 0.3s ease);
    &:hover {
        background: $title-color;
    }
     // RTL-specific styles
  :dir(rtl) & {
    left: 0 !important;
    right: auto;
  }

  // LTR-specific styles
  :dir(ltr) & {
    right: 0 !important;
    left: auto;
  }
    ul {
        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            &:last-child { 
                border-bottom: 0;
            }
            a {
                padding: 5px;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                border-radius: 5px;
                svg {
                    color: $white;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
.sidebar-settings {
    width: 450px;
    background-color: $white;
    @include position(fixed,0,-450px,null,null);
    height: 100vh;
    background-color: rgb(0,0,0,0.2);
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    @include transition(ease all 0.8s);
    &.show-settings {
         right: 0;
        @include transition(ease all 0.8s);
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
        width: 100%;
    }
}
.sidebar-content {
    max-width: 450px;
    width: 100%;
    @include position(absolute,0,0,null,null);
    background: $white;
    height: 100vh;
    overflow-x: hidden;
    .sidebar-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        @include margin-padding(null, 20px);
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: $primary;
        h5 {
            font-size: $font-size-20;
            color: $white;
            font-weight: $font-weight-bold;
            margin-bottom: 0;      
            text-transform: uppercase;      
            @include respond-below(custom991) {
                font-size: $font-size-18;
            }
        }
        p {
            color: $white;
        }
        a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            border: 1px solid $white;
            width: 26px;
            height: 26px;
            border-radius: 5px; 
            color: $white;
            svg {
                width: 15px;
                height: 15px;
                color: $white
            }
        }
    }
    .theme-head {
        margin-bottom: 20px;
        h6 {
            color:$text-color;
            font-weight: $font-weight-bold;
            font-size: $font-size-18;
        }
    }
    .sidebar-body {
        @include margin-padding(null, 20px);
        .theme-title {
            font-size: $font-size-18;
            font-weight: $font-weight-bold;
            color: $gray-600;
            margin-bottom: 10px;
        }
    }
}
.switch-wrapper {
    border-bottom: 1px solid $gray-400;
    @include margin-padding(0 0 20px, 0 0 20px);
    #dark-mode-toggle {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        .light-mode {
            border-radius: 5px 0 0 5px;
        }
        .dark-mode {
            border-radius: 0 5px 5px 0;
        }
        .light-mode, .dark-mode {
            background: $gray-400;
            font-size: $font-size-16;
            color: $black;;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            @include margin-padding(null, 11px 18px);
            cursor: pointer;
            img {
                filter: brightness(0) saturate(100%)
            }
            &.active {
                background: $orange;
                color: $white;
                img {
                    filter:  brightness(0) invert(1);
                }
            }
        }
    }
}
.layout-wrap {
    margin-bottom: 20px;
    .status-toggle {
        .checktoggle {
            background: $black;;
            width: 25px;
            height: 15px;
            :after {
                width: 12px;
                height: 12px;
                right: 4px;
            }
        }  
    } 
    .layout-img {
        img {
            max-width: 120px;
        }
    }         
    .status-text {
        font-size: $font-size-16;
        color: $black;;
    }
}
.layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block;
}
.theme-mode {
    .theme-body-main {
        padding: 20px 20px 0;
    }
    @include margin-padding(0 0 20px,0);
    .layout-wrap {
        .check {
            + label {
                text-align: center;
                cursor: pointer;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                flex-direction: column;
                img {
                    border-radius: 8px;
                    border: 1px solid $gray-100;
                }
                .theme-name {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    color: $text-color;
                    font-weight: $font-weight-bold;
                    font-size: $font-size-base;
                    margin-top: 10px;
                    position: relative;
                    &::before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        border: 1px solid #919EAB4D;
                        display: block;
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                }
            }
            &:checked {
                + label {
                    img {
                        border-color: $primary;
                    }
                    .theme-name {
                        &::after {
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: $primary;
                            position: absolute;
                            left: 4px;
                        }
                    }
                }
            }
        }
    }
}
#layout_mode {
    @include respond-below(custom991) {
        display: none;
    }
}	
.sidebar-footer {
    position: sticky;
    padding: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0px 4px 60px 0px rgba(190, 190, 190, 0.27);
    z-index: 110;
}