@media print {
  @page {
    size: A3;
  }

  .actions-button {
    display: none !important;
  }
}

.info-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 7px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  }

  &__title {
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__value {
    font-family: 'Poppins', sans-serif;
    color: #696969;
    font-size: 16px;
    font-weight: 300;
  }
}

.text-subheading {
  color: #696969;
  font-size: 16px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h6 {
  margin: 0.3rem 0;
  font-size: 1rem;
  font-weight: 500;

  &:first-child {
    font-weight: 700;
    color: #333;
    font-size: 18px;
  }

  &:nth-child(2) {
    color: #555;
  }
}

.card-border {
  border: 1px solid #d5cbcb;
}
h6:first-child {
  font-weight: 700;
  color: #333;
 font-size: 18px;
}
h6:nth-child(2) {
  color: #555;
}
.card-border{
  border: 1px solid #d5cbcb; 
}
