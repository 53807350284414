.Spin-style.fullHeight {
  height: 183vh !important;
}

.ant-spin {
  color: #ff6000 !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}
.ant-spin.Spin-style--solidWhite {
  background-color: #fff !important;
}

.ant-spin-dot-item {
  background-color: #ff6000 !important;
}

.ant-spin.Spin-style.positionFixed {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Spin-style,
.Spin-style.positionAbsolute {
position: absolute;
left: 0;
width: 100%;
height: 100%;
z-index: 9999;
display: flex;
align-items: center;
justify-content: center;
}
.actions_addModalFormItem label span{
  color: #000 !important;
}
.actions_addModalFormItem label span span{
  color: #FF0000 !important;
}
.status-label{
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
}