.alert {
  padding: 0.625rem 0.85rem;
  border-radius: $border-radius;
  font-size: 0.8125rem;
  &.alert-dismissible {
    padding: 0.625rem 2.25rem 0.625rem 0.85rem;
  }
  i {
    font-size: $font-size-20;
  }
  .btn-close {
    background-image: none;
    padding: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: $font-size-18;
      line-height: 18px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  .alert-link {
    font-weight: $font-weight-semibold;
  }
}
[dir="rtl"] {
  .alert.alert-dismissible {
    padding: 0.625rem 0.85rem 0.625rem 2.25rem;
  }
}
@mixin custom-close($color) {
  background-color: $color;
  color: $white;
  opacity: 1;
  border-radius: 50px;
  box-shadow: $box-shadow;
  padding: 0.85rem;
}
.alert-warning {
  background-color: rgba($warning, 0.1);
  color: $warning;
  border-color: rgba($warning, 0.1);
  .alert-link {
    color: $warning;
  }
  .btn-close {
    color: $warning;
    &.custom-close {
      @include custom-close($warning);
    }
  }
}
.alert-primary {
  background-color: rgba($primary, 0.1);
  color: $primary;
  border-color: rgba($primary, 0.1);
  .alert-link {
    color: $primary;
  }
  .custom-alert-icon {	
	color: $primary;
  }
  .btn-close {
    color: $primary;
    &.custom-close {
      @include custom-close($primary);
    }
  }
}
.alert-secondary {
  background-color: rgba($secondary, 0.1);
  color: $secondary;
  border-color: rgba($secondary, 0.1);
  .alert-link {
    color: $secondary;
  }
  .btn-close {
    color: $secondary;
    &.custom-close {
      @include custom-close($secondary);
    }
  }
}
.alert-success {
  background-color: rgba($success, 0.1);
  color: $success;
  border-color: rgba($success, 0.1);
  .alert-link {
    color: $success;
  }
  .btn-close {
    color: $success;
    &.custom-close {
      @include custom-close($success);
    }
  }
}
.alert-info {
  background-color: rgba($info, 0.1);
  color: $info;
  border-color: rgba($info, 0.1);
  .alert-link {
    color: $info;
  }
  .btn-close {
    color: $info;
    &.custom-close {
      @include custom-close($info);
    }
  }
}
.alert-danger {
  background-color: rgba($danger, 0.1);
  color: $danger;
  border-color: rgba($danger, 0.1);
  .alert-link {
    color: $danger;
  }
  .btn-close {
    color: $danger;
    &.custom-close {
      @include custom-close($danger);
    }
  }
}
.alert-light {
  background-color: $light;
  color: $text-color;
  border-color: $light;
  .alert-link {
    color: $text-color;
  }
  .btn-close {
    color: $text-color;
    &.custom-close {
      @include custom-close($light);
    }
  }
}
.alert-dark {
  background-color: rgba($dark, 0.1);
  color: $text-color;
  border-color: rgba($dark, 0.1);
  .alert-link {
    color: $text-color;
  }
  .btn-close {
    color: $white;
    &.custom-close {
      @include custom-close($dark);
    }
  }
}
@mixin alert-solid-color($color) {
  background-color: $color;
  color: $white;
  border-color: $color;
  .btn-close {
    color: $white;
  }
}
.alert-solid-primary {
  @include alert-solid-color($primary);
}
.alert-solid-secondary {
  @include alert-solid-color($secondary);
}
.alert-solid-warning {
  @include alert-solid-color($warning);
}
.alert-solid-info {
  @include alert-solid-color($info);
}
.alert-solid-success {
  @include alert-solid-color($success);
}
.alert-solid-danger {
  @include alert-solid-color($danger);
}
.alert-solid-light {
  background-color: $light;
  color: $text-color;
  border-color: $default-border;
}
.alert-solid-dark {
  @include alert-solid-color($dark);
}
@mixin outline-alert($color) {
  background-color: $white;
  color: $color;
  border-color: $color;
  .btn-close {
    color: $color;
  }
}
.alert-outline-primary {
  @include outline-alert($primary);
}
.alert-outline-secondary {
  @include outline-alert($secondary);
}
.alert-outline-info {
  @include outline-alert($info);
}
.alert-outline-warning {
  @include outline-alert($warning);
}
.alert-outline-success {
  @include outline-alert($success);
}
.alert-outline-danger {
  @include outline-alert($danger);
}
.alert-outline-light {
  background-color: $white;
  color: $text-color;
  border-color: $light;
}
.alert-outline-dark {
  @include outline-alert($dark);
}
@mixin custom-alert($color) {
  border-inline-start: 0.313rem solid $color !important;
  color: $text-muted;
  background-color: $white;
  border: 1px solid $default-border;
  font-size: 0.813rem;
  .btn-close {
    color: $text-color;
  }
}
.alert-primary.custom-alert-icon {
  @include custom-alert($primary);
  & > i {
	color: $primary;
  }
}
.alert-secondary.custom-alert-icon {
  @include custom-alert($secondary);
  & > i {
	color: $secondary;
  }
}
.alert-warning.custom-alert-icon {
  @include custom-alert($warning);
  & > i {
	color: $warning;
  }
}
.alert-danger.custom-alert-icon {
  @include custom-alert($danger);
  & > i {
	color: $danger;
  }
}
.alert-success.custom-alert-icon {
  @include custom-alert($success);
}
.alert-info.custom-alert-icon {
  @include custom-alert($info);
}
.alert-light.custom-alert-icon {
  @include custom-alert($light);
}
.alert-dark.custom-alert-icon {
  @include custom-alert($dark);
}
.custom-alert1 {
  margin-block-end: 0;
  background-color: $white;
  border: 0;
  padding: 1.25rem;
  color: $text-color;
  p {
    margin-block-end: 2.5rem;
    color: $text-muted;
    font-size: 0.8rem;
  }
  .custom-alert-icon {
	font-size: $font-size-40;
    margin-bottom: 0.85rem;
	i {		
		font-size: $font-size-40;
	}
  }
  .btn-close {
    padding: 0;
    margin-block-end: 1rem;
  }
  &.alert-primary {
    border-block-start: 0.313rem solid $primary;	
	.custom-alert-icon {	
		color: $primary;
	}
  }
  &.alert-secondary {
    border-block-start: 0.313rem solid $secondary;	
	.custom-alert-icon {	
		color: $secondary;
	}
  }
  &.alert-warning {
    border-block-start: 0.313rem solid $warning;	
	.custom-alert-icon {	
		color: $warning;
	}
  }
  &.alert-danger {
    border-block-start: 0.313rem solid $danger;	
	.custom-alert-icon {	
		color: $danger;
	}
  }
}
.alert-img {
  display: flex;
  align-items: center;
  .avatar {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 400px) {
  .btn-close.custom-close {
    margin: 9px 9px 9px 0;
  }
}
.op-1-1 {
    opacity: 1;
}
.op-0 {
    opacity: 0;
}
.op-1 {
    opacity: 0.1;
}
.op-2 {
    opacity: 0.2;
}
.op-3 {
    opacity: 0.3;
}
.op-4 {
    opacity: 0.4;
}
.op-5 {
    opacity: 0.5;
}
.op-6 {
    opacity: 0.6;
}
.op-7 {
    opacity: 0.7;
}
.op-8 {
    opacity: 0.8;
}
.op-9 {
    opacity: 0.9;
}
