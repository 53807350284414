.userManagementModal_form {
    display: flex;
  }
  
  .userManagementModal_formLeftArea,
  .userManagementModal_formRightArea {
    width: 100%;
  }

  .userManagement_addModalFormItem {
    width: 48%;
  }
  .customerActions_addModalFormItem label span{
    color: #000 !important;
  }
  .customerActions_addModalFormItem label span span{
    color: #FF0000 !important;
  }