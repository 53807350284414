/* Remove row and cell padding */
.table-responsive .ant-table-tbody>tr>td {
    padding: 2 !important;
    /* Remove padding from table cells */
}

.table-responsive .ant-table-tbody>tr>td {
    padding-left: 16px !important;
    /* Add padding on the left side */
}

.table-responsive .ant-table-thead>tr>th {
    padding: 1 !important;
    /* Remove padding from table headers */
}

/* Remove row margin */
.table-responsive .ant-table-row {
    margin: 0 !important;
}

.table .edit-delete-action {
    height: 35px !important;
}

.card {
    margin-bottom: 0.5rem !important;
}

.ant-form-item {
    margin-bottom: 0px !important;
}

.card.table-list-card .table-top {
    padding: 8px 8px 0px !important;
    margin-bottom: 10px !important;
}

.card.table-list-card .table-responsive {
    padding: 0px !important;
}

.input-blocks {
    margin-bottom: 1px !important;
}
.page-header {
    margin-bottom: 5px !important;
}
/* .sidebar .sidebar-menu > ul > li.submenu-open ul li a, .sidebars .sidebar-menu > ul > li.submenu-open ul li a {
    padding: 5px 5px 5px 18px !important;
} */