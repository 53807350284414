.addProduct_form--status_area
{
    display: flex;
    justify-content: space-between;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    flex-direction: column !important;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-form-item .ant-form-item-label {
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: start !important;
    vertical-align: middle;
}
.product_form_generate_code--button{
    margin-top: 2px !important;
}
.react-datepicker-wrapper{
    width: 100% !important;
}