.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    min-height: 200px !important;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
    min-height: 200px !important;
    
}
.table_input_width-100{
    width: 100px !important;
}