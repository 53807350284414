.dashboard-container {
  
  .dashboard-tabs {
    .ant-tabs-tab {
      font-size: 16px;
      font-weight: 500;
      transition: color 0.3s ease;

      &:hover {
        color: #FF9F43;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FF9F43;
        font-weight: bold;
      }
    }

    .ant-tabs-ink-bar {
      background-color: #FF9F43;
    }
  }
}
