.userManagement_addModalWrapper .ant-input-number {
  width: 100% !important;
}

.userManagement_addModalWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .profileImg div img {
    width: 70px !important;
    height: 70px !important;
    margin-right: 10px;
  }