/* Card Container */
.card-container {
    flex-wrap: wrap;
    gap: 10px; /* Space between cards */
    justify-content: space-around;
    margin: 20px;
    max-height: 500px; /* Adjust height as needed */
    overflow-y: auto; /* Add vertical scrollbar */
    padding-right: 15px; /* Add some space for scrollbar */
  }
  
  /* Individual Card Styling */
  .custom-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    width: 300px;
    margin: 5px; /* Ensure margin around each card */
  }
  
  
  /* Card Header Styling */
  .custom-card .ant-card-head {
    background-color: #f9fafc;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .card-badge {
    background: #d9534f;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
    padding: 3px 6px;
  }
  
  .card-extra {
    background: #007bff;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    padding: 3px 6px;
  }
  
  /* Customer Info Section */
  .card-info {
    font-size: 14px;
    margin: 10px 0;
  }
  
  /* Actions Section */
  .card-actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0;
  }
  
  .btn {
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 3px;
    transition: background 0.3s ease;
    padding: 5px 10px;
  }
  
  .btn-add {
    background: #007bff;
    color: white;
  }
  
  .btn-confirm {
    background: #28a745;
    color: white;
  }
  
  .btn-cancel {
    background: #dc3545;
    color: white;
  }
  
  .btn:hover {
    opacity: 0.8;
  }
  
  .card-icon {
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
  }
  
  /* Checklist Section */
  .card-checklist {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .checklist-title {
    font-weight: bold;
  }
  
  .checklist-time {
    color: #dc3545;
    font-size: 12px;
    font-style: italic;
  }
  
  .btn-expand {
    background: #f8f9fa;
    border: 1px solid #ccc;
    color: #333;
    font-size: 14px;
    padding: 3px 6px;
    border-radius: 3px;
  }
  